@import "./colors.less";


.trigger {
  padding: 0 30px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s; 
}
.trigger2{
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s; 
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  padding: 20px;
}

.site-layout .site-layout-background {
  background: var(--body-color);
}

.site-layout .site-header-background {
  background: var(--light-text);
}

// .ant-layout-sider .ant-layout-sider-children{
//   background-color: var(--sidebar-background);
// }

.doctor-layout.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: .doctor_app()[--secondary-color];

  .ant-menu-title-content {
    color: white !important;
  }

  svg {
    filter: brightness(0) invert(1);
  }
}

.doctor-layout.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-selected {
  svg path{
    fill: #23BCB5 !important;
  }
}


.boss-layout.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #E6F7FF!important;

  .ant-menu-title-content {
    color: #4DA9D0 !important;
  }

  svg path{
    fill: #4DA9D0 !important;
  }
}




.ant-menu.ant-menu-inline-collapsed > .ant-menu-item{
  padding: 7px calc(50% - 18px / 2)!important;
}

.App {
  // background-color: .doctor_app()[--light-background]
  background-color: var(--light-background);

  .parent-layout {
    overflow: hidden;
    display: flex;

    .site-layout {
      flex: 1;
      max-width: 100%;

      .admin-app, .doctor-app {
        height: calc(100vh - 64px);
        overflow-y: auto;
      }
    }
  }
}

.debug-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  .debug-info {
    padding: 10px;
    justify-content: space-between;
    background: #dada31;
    display: flex;
    flex-direction: row;

    .ant-tag {
      cursor: pointer;
    }
  }

  .App {
    width: 100%;
    flex-grow: 1;
  }
}
