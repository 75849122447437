.auth-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  form {
    min-width: 300px;
    .ant-space{
      width: 100%;
    }
  }
}


