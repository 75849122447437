@import "./colors.less";

.square-timeline {
  .ant-steps-item-tail::after {
    border-left: 2px dashed .doctor_app() [--dark-color];
    opacity: 0.5 !important;
    background-color: none !important;
    margin-left: -5px !important;
  }

  .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-tail::after {
    border-left: 2px dashed .doctor_app() [--dark-color];
    opacity: 0.5 !important;
    background-color: none !important;
  }
  .ant-steps-item-content .ant-steps-item-title {
    width: 100% !important;
  }
}

/*
only for mobile users specific for patient web app  
*/
@media (max-width: 767px) {
  .square-timeline {
    .ant-steps-item-content .ant-steps-item-title {
      font-size: 14px !important;
    }
    .ant-steps-item-title {
      padding-right: 0;
    }
  }

  #AppointmentDetailPage h2.doctor-secondary-color.capitalize {
    line-height: 24px;
    margin-bottom: 0;
  }
}

#AppointmentDetailPage {
  padding-bottom: 0;
}
#AppointmentDetailPage .ant-divider-horizontal {
  margin-top: 0;
}

.recentAppointmentDivider {
  border-top: 1px solid #163b6d4a;
}

.supportMenu {
  list-style: none;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0;
}

.supportMenu li {
  padding-top: 13px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(22 59 109 / 30%);
  font-weight: 600;
  vertical-align: middle;
  cursor: pointer;
  font-size: 16px;
}

.supportMenu li .anticon-right {
  margin-top: 4px;
}

.supportMenu li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.joinCallPopup .ant-modal-title {
  font-size: 20px;
}
.joinCallPopup .ant-modal-header {
  border-bottom: none;
}

.symptomsDivider {
  border-top: 1.53px solid #163b6d;
  opacity: 0.2;
}

.PatientNameCard small.sub-text.dateColors.light-text {
  padding-left: 5px;
}

#maxIDDoctor1,
#maxIDDoctor2,
#maxIDDoctor3,
#maxIDDoctor4 {
  font-size: 14px !important;
}

small.sub-text.dateColors.light-text {
  font-size: 14px !important;
}

.pastPrescriptionSelect .ant-select-selector {
  padding-left: 3px !important;
}

.pastPrescriptionAvtar > img {
  width: 75%;
  height: auto;
  margin: auto;
  padding-top: 5%;
}

.plr20 {
  padding-left: 20px;
  padding-right: 20px;
}
.plud10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

img.avatarImagePreview {
  width: 65%;
  margin-top: 10%;
}

.pastPrescriptionSelect .ant-select-arrow {
  top: 52%;
}

.selectProfilePatient small.sub-text.dateColors.light-text {
  opacity: 1;
}

.callTageType-patient {
  padding-top: 2px;
  padding-bottom: 2px;
}

.callTageType-patient span.ant-badge-status-dot.ant-badge-status-red {
  top: 0px;
}

.appointmentTimeline.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 1px;
  height: 95%;
  top: 28px;
  opacity: 0.3 !important;
  position: absolute;
  z-index: 0;
  bottom: 0px;
  left: -1px;
}

.appointmentTimeline.square-timeline
  .ant-steps-item-finish
  .ant-steps-item-container
  .ant-steps-item-tail::after {
  border-left: 2px solid #23bcb5;
  opacity: 1 !important;
  background-color: none !important;
}

.patient-border-button-transparent,
.patient-border-button-transparent:focus,
.patient-border-button-transparent:hover {
  border: 1px solid .doctor_app() [--secondary-color] !important;
  color: .doctor_app() [--secondary-color] !important;
  background-color: transparent !important;
  text-shadow: none !important;
}

.wVideosContainer > .ant-col:nth-child(odd) {
  padding-right: 10px !important;
  padding-left: 20px !important;
}

.wVideosContainer > .ant-col:nth-child(even) {
  padding-right: 20px !important;
  padding-left: 10px !important;
}

.wVideosContainer iframe {
  border-radius: 5px;
}

.uploadButtonStyle span {
  float: left;
  display: block;
  height: 40px;
  line-height: 40px;
}

.uploadButtonStyle span:nth-child(1) {
  width: 10%;
}

.uploadButtonStyle span:nth-child(2) {
  width: 70%;
  text-align: left;
  padding-left: 10px;
}

.uploadButtonStyle span:nth-child(3) {
  width: 15%;
  float: right;
}

#grayBGColor {
  background-color: #f0f5f8 !important;
}

.doctor-secondary-button.ant-btn[disabled],
.doctor-secondary-button.ant-btn[disabled]:hover,
.doctor-secondary-button.ant-btn[disabled]:focus,
.doctor-secondary-button.ant-btn[disabled]:active {
  color: white !important;
  border: 1px solid .doctor_app() [--dark-color] !important;
  background-color: .doctor_app() [--dark-color] !important;
  text-shadow: none;
  box-shadow: 0px 4.6608px 15.536px #9bb9c8 !important;
  opacity: 0.3;
}

