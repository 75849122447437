@import "~antd/dist/antd.less";
@import url("./styles/css/style.less");
@import "./styles/index";
@import url("./styles/colors.less");
.App {
  height: 100%;
}

.ant-layout {
  height: 100% !important;
  overflow-y: scroll !important;
  background-color: white;

  .ant-layout-header {
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid var(--border-color);
  }

  .ant-layout-content {
    padding: 20px;
  }

  .ant-layout-sider {
    border-right: 1px solid #dddddd;
  }
}

.fixed-header-comp {
  max-height: calc(100vh - 230px);
  overflow-y: scroll;
}

.soft-hidden {
  position: fixed;
  height: 1px;
  width: 1px;
  left: -1000px;
  overflow: hidden;
  opacity: 0;
}

#video-section {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background-color: white !important;
}

.video-section-non {
  height: 1px;
  overflow: hidden;
}
.error-table-row {
  background: rgba(141, 9, 9, 0.46);
}
@collapsedSidebarWidth: 80px;
@expandedSidebarWidth: 200px;

.collapsed-side-bar {
  max-width: ~"calc(100% - @{collapsedSidebarWidth})" !important;
}
.expanded-side-bar {
  max-width: ~"calc(100% - @{expandedSidebarWidth})" !important;
}

// 100ms

.hms-ui-c-dhzjXW-iiTTjde-css {
  padding: 2px 8px 0px !important;
}
.hms-ui-c-fnFpiX {
  padding-bottom: 0px !important;
}

.hms-ui-c-enDtOL {
  padding-top: 10px !important;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1;
  min-width: unset !important;
}

@primary-color: #4DA9D0;@heading-color: #163B6D;