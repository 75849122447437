@import "./colors.less";

.chat .ant-drawer-footer {
  background-color: #edeef1 !important;
}

.left-message {
  border: 1px solid lightgray;
  background: #ffffff;
  box-shadow: 0px 1.63269px 1.63269px rgba(49, 19, 46, 0.15);
  border-radius: 14.6942px;
  padding: 16px 20px;
  min-width: 20% !important;
  max-width: 50% !important;
  padding-bottom: 20px;
  float: left;
  display: inline-block;
  word-break: break-all;
}

.right-message {
  background: .doctor_app() [--secondary-color] !important;
  box-shadow: 0px 1.63269px 1.63269px rgba(49, 19, 46, 0.15);
  border-radius: 14.6942px;
  padding: 16px 20px;
  color: white !important;
  min-width: 20% !important;
  max-width: 50% !important;
  float: right;
  display: inline-block;
  word-break: break-all;
}
