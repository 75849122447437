

:root {
    --secondary-color: #23BCB5;
    --mid-color: #5AB9B4;
    --heading-color: #616161;
    --sub-text: #888888;
    --light-text: #fff;
    --body-color: #EAF4F3;
    --sidebar-background:#FAFAFA;
    --border-color:#F5F5F5;
    --card-shadow : 0px 1px 3px #00000029;
    --light-background:#F7F8FA;
    --primary-color:#003873;
    }

.doctor_app {
    --app-background:#E0EBF1;
    --secondary-color: #23BCB5;
    --primary-color:#003873;
    --box-shadow : 0px 4px 13px #9bb9c8 ;
    --sidebar-background:#C1DAE8;
    --primary-tag-color:#003e721a;
    --secondary-tag-color:#5ab9b452;
    --dark-color:#163B6D;
}

.crm_app {
    --text-light-color: #f9f9f9;
    --text-dark-color: #223042;
    --text-secondary-color: #858e93;
    --app-background: #f7f8fb;
    --primary-color: #37c2d8;
    --primary-color-light: rgb(235, 247, 253);
    --success-color: #37cb95;
    --success-color-light: #d2f5e8;
    --warning-color: #EB872A;
    --warning-color-light: #f8e6d5;
    --secondary-color: #d7f3f7;
    --box-shadow : 0px 3px 10px #9BB9C8;
    --dark-color:#213042;
    --sidebar-background:#001529;
    --danger-color: #e1454e;
    --danger-color-light: #ffe2e3;
    --light-color: #b7bcbf;
    --card-border-color: #d9d9d9;
    --divider-color: #c6c9cc;
}