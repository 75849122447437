.template-form-builder {
  .childrow-ghost, .row-ghost {
    height: 40px;
    width: 100px;
    padding-left: 3px;
    border-radius: 2px;
  }

  .childrow-ghost {
    background-color: #C8FFE9;
  }

  .row-ghost {
    background-color: #E9CFBC;
  }

  .form-builder {
    .content-wrap {
      padding: 0 30px;
    }

    .edit-container {
      font-size: 12px;
      line-height: 11px;

      .sweet-alert {
        line-height: 22px;
      }

      .actions .mdi {
        color: #36a2a1;
        font-size: 20px;
        margin: 0 5px;
        cursor: pointer;
      }
    }

    .nav-profile {
      .mdi {
        border: none;
      }
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 2px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #36a2a1;
      border-radius: 100px;
    }

    .section-selector.row, .editor.row {
      margin-left: 0;
      margin-bottom: 5px;
    }

    .editor .col-md-9 {
      padding-left: 0;
    }

    .editor .col-md-3 {
      padding-left: 0;
    }

    li .drag {
      cursor: move;
    }

    .help li {
      list-style: initial;
    }

    li {
      list-style: none;
    }

    ul {
      padding-left: 0;
    }

    .section-selector ul {
      margin: 0 5px 0 0;
      display: flex;
      flex-wrap: wrap;
    }

    .fixme { //fixes properties-editor
      position: fixed;
      width: 308px;
      right: 25px;
      top: 77px;
    }

    .fixme-actions { //fixes actions column
      position: fixed;
    }

    .temp-tab-content-margin {
      margin-left: 56px;
    }


    .highlight {
      box-shadow: 0 0 3px 0 rgba(55, 107, 148, 0.6);
    }

    .properties-editor {
      height: 500px;
      overflow-y: scroll;
      overflow-x: hidden;
      width: 327px;

      h6 {
        margin-bottom: 1.5em;
        margin-left: 17px;
      }
    }

    .section-form, .row-form, .child-row-form {
      border: 1px dashed #bbb;
      margin-bottom: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }

    .badge-outline-info {
      margin-right: 3px;
      margin-bottom: 5px;
      max-width: 150px;
    }

    .required {
      font-size: 16px;
      color: red;
    }

    .mobile-only-view-label {
      font-size: 16px;
      color: red;
      font-style: italic;
    }

    .tooltip-info {
      width: 150px;
      color: white;
    }

    .__react_component_tooltip.show {
      opacity: 1;
    }

    .childrow-header {
      padding-right: 0;
      background-color: azure;
    }

    .childrow-header-heading {
      padding-right: 0;
      background-color: antiquewhite;
    }

    .childrow-body {
      padding-left: 0;
      padding-bottom: 4px;
    }

    .left-border {
      border-left: 1px dashed #bbb;
    }

    .center-align {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .col-form-item {
      margin: 4px 4px 0 0;
      background-color: lightgoldenrodyellow;
      border: 1px dashed #bbb;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      border-radius: 3px;
      padding: 3px 0 0 0;
    }

    .section-form, .row-form, .child-row-form {
      .card-header, .card-body {
        padding: 5px 5px;
      }
    }

    .section-form {
      .card-header {
        background-color: beige;
      }
    }

    .section-header {
      font-size: 14px;
    }

    .row-form {
      .card-header {
        background-color: bisque;
      }
    }

    .row-form {
      background-color: bisque;
    }


    .card-header {
      height: 45px;
    }

    .element-names {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .component-selector-dialog {
      max-width: 70%;
    }

    // .prop-container {
    //   display: flex;
    //   flex-wrap: wrap;
    // }

    .form-place-holder {
      border: 1px dashed #bbb;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      font: 12px bold, "Vollkorn";
      color: #bbb;
      cursor: pointer;
    }

    .modal-backdrop {
      opacity: 0.5;
    }

    .modal-content {
      background-color: white;
    }

    .action-bar {
      position: relative;
    }

    .static-button {
      position: absolute;
      top: 0;
      right: 0;
    }

    .save-button {
      position: absolute;
      top: 0;
    }

    .save-form-label {
      color: red;
    }

    .action-bar .tab-content {
      padding: 0;
      width: 95%;
    }

    .actions-column.nav {
      width: 3%;

      .nav-link {
        padding: 5px;
      }

      span.nav-link {
        visibility: hidden;
      }
    }

    .top-scroller {
      position: fixed;
      bottom: 50px;
      right: 50%;
      font-size: 15px;
      padding: 2px;
      display: none;
      z-index: 2;
      text-align: center;
      cursor: pointer;
      background: #36a2a1;
      color: #fff;
      height: 40px;
      width: 60px;
      box-shadow: 0px 0px 31px 6px rgba(0, 0, 0, 0.17);
    }

    .top-scroller:hover {
      background-color: #17ad97;
      border-color: #15a28d;
    }

    h6 {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      font-size: 13px;
      display: inline-block;
    }

    .col-display-block {
      display: flex;
      flex-direction: column;
    }

    .col-display-inline {
      display: flex;
    }

    .media-only-mobile {
      @media (min-width: 768px) {
        display: none;
      }
    }

    .media-only-screen {
      @media (max-width: 767px) {
        display: none;
      }
    }


    .timeline-wrapper .timeline-panel {
      box-shadow: 1px 1px 1px 0 rgba(1, 1, 1, 0.1);
    }

    .timeline-wrapper .timeline-panel:hover {
      box-shadow: -17px 1px 1px 0 rgba(1, 1, 1, 0.1);
      cursor: pointer;
    }

    .timeline-scroller {
      margin-top: 50px;
      padding-left: 6px;
      overflow: auto;
      width: 234px;
    }

    #settings-trigger {
      width: 0px;
      height: 30px;
    }
  }

}
